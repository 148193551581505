.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: var(--ion-color-primary);
}

mat-dialog-container {
  max-width: 600px;
}

mat-progress-bar {
  .mat-progress-bar-fill::after {
    background-color: var(--ion-color-primary) !important;
  }
  &.progress-top {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999999;
  }
  &.progress-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999999;
  }
}
