ion-popover {
  &.no-border-radius {
    &::part(content) {
      border-radius: 0 !important;
    }
  }

  &.popover-75 {
    ion-title {
      padding: 0;
    }
    --max-width: 75vw;
    --width: fit-content;
  }

  &.popover-80 {
    ion-title {
      padding: 0;
    }
    --max-width: 80vw;
    --width: fit-content;
  }

  &.popover-400px {
    --width: 400px;
  }

  &.blurred-backdrop {
    backdrop-filter: blur(5px);
  }
  &.transparent-content {
    --backdrop-opacity: 0 !important;
  }
  &.transparent-backdrop {
    --background: transparent !important;
  }
  &.display-contents {
    &::part(content) {
      display: contents;
    }
  }
  &.half-width {
    --min-width: 50vw;
    --width: fit-content;
  }
}

select-categoria {
  .swiper-slide:not(.swiper-slide-active) > div {
    height: 0;
  }
}
