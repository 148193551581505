/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "swiper/css/bundle";

@import "./theme/custom-variables.scss";
@import "./theme/material-custom.scss";
@import "./theme/overlay-styles.scss";

input:-webkit-autofill,
input:-webkit-autofill:focus {
  // transition: background-color 600000s 0s, color 600000s 0s;
  transition-delay: 200000s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

ion-toolbar {
  --background: var(--ion-color-primary);
  --color: var(--ion-color-primary-contrast);

  ion-button {
    --color: var(--ion-color-primary-contrast);
  }
}

ion-content {
  --background: var(--ion-color-primary-tint);
  --color: var(--ion-color-primary-contrast);

  ion-list {
    background: transparent !important;
  }
}

ion-list {
  padding: 0 !important;
}

ion-item {
  --background: rgba(var(--ion-color-primary-rgb), 0.7);
  --color: var(--ion-color-primary-contrast);
}

.bold,
.bold * {
  font-weight: bold !important;
  --font-weight: bold !important;
}

*.hover-pointer {
  cursor: pointer;
}

@media screen and(min-width: 992px) {
  .mobile-only,
  *.mobile-only {
    display: none !important;
  }
}

@media screen and(max-width: 991px) {
  .web-only,
  *.web-only {
    display: none !important;
  }
}

ion-icon {
  pointer-events: none;
}

ion-label,
ion-text {
  &.required::after {
    content: "*";
    color: var(--ion-color-danger);
    margin-left: 5px;
  }
}

*.flex-column {
  display: flex !important;
  flex-direction: column !important;
  &.wrap {
    white-space: pre-line;
  }
}
